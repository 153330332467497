/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import Header from './Header';
import Content from './Content';
import ParticalScene from './ParticleScene';

const Layout = ({ title, children }) => {
  return (
    <ThemeProvider theme={{}}>
     <Header title="Hi, I'm Nick" />
    </ThemeProvider>
  )
}

export default Layout
