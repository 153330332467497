import * as React from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useCamera } from 'react-three-fiber'

const onGLCreate = ({ gl, camera }) => {
  gl.gammaInput = true;
  gl.toneMapping = THREE.ACESFilmicToneMapping;
  gl.setClearColor(new THREE.Color('#e9e9e9'))
};

const X_IDX = 0;
const Y_IDX = 1;
const Z_IDX = 2;

const D_Y = 10;

const pointVector = new THREE.Vector3(0, 0, 0);
const clock = new THREE.Clock(true);

const Stars = ({ count = 1000 }) => {
  const boundingBoxGeometry = React.useRef();
  const particles = React.useRef();

  const minY = React.useRef(0);
  const maxY = React.useRef(0);
  const velocityYMap = React.useRef({});

  const positions = React.useMemo(() => {
    const positions = [];
  
    for (let i = 0; i < count; i++) {
      const r = 100;
      const theta = 2 * Math.PI * Math.random();
      const phi = Math.acos(2 * Math.random() - 1);

      const x = r * Math.cos(theta) * Math.sin(phi) + (-2000 + Math.random() * 4000);
      const y = r * Math.sin(theta) * Math.sin(phi) + (-2000 + Math.random() * 4000);
      const z = r * Math.cos(phi) + (-1000 + Math.random() * 2000);

      positions.push(x);
      positions.push(y);
      positions.push(z);

      velocityYMap.current[i + Y_IDX] = Math.floor(Math.random() * 4) + D_Y;

      if (y < minY.current) {
        minY.current = y;
      }

      if (y > maxY.current) {
        maxY.current = y;
      }
    }

    return new Float32Array(positions);
  }, [count]);

  useFrame(() => {    
    const position = particles.current.geometry.attributes.position;
    particles.current.geometry.attributes.position.needsUpdate = true;
    
    const dt = clock.getDelta();

    for (let i = 0; i < position.array.length; i += 3) {
      const curIdx = i + Y_IDX;
      const curY = position.array[curIdx];

      position.array[curIdx] += velocityYMap.current[curIdx];

      if (curY > maxY.current) {
        position.array[i + Y_IDX] = minY.current;
      }
    }
  });

  return (
    <points ref={particles}>
      <boxBufferGeometry attach="geometry" args={[100, 100, 100]}>
        <bufferAttribute attachObject={['attributes', 'position']} count={positions.length / 3} array={positions} itemSize={3} />
      </boxBufferGeometry>
      <pointsMaterial attach="material" size={100} sizeAttenuation color="black" />
    </points>
  )
};

const ParticleScene = () => {
  return (
    <Canvas concurrent onCreated={onGLCreate} camera={{ position: [0, 0, 2000], near: 0.01, far: 10000, fov: 70 }}>
      <fog attach="fog" args={['#000000', 100, 700]} />
      <ambientLight intensity={0.25} />
      <Stars />
    </Canvas>
  );
};

export default ParticleScene;
