import * as React from 'react';
import styled from 'styled-components';

const Text = styled.span`
  font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 24px;
  text-rendering: optimizelegibility;
`;

export default Text;
