import * as React from "react"
import { Link } from "gatsby"
import styled from 'styled-components';

import Hero from './Hero';
import Text from './Text';
import ParticleScene from './ParticleScene';

const Container = styled.header`
  position: relative;
  background-color: transparent;
  padding-bottom: 2vw 0;
  color: white;

  width: 100vw;
  height: 100vh;

  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.6)
  )
`;

const Navbar = styled.nav`
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-width: 1220px;
  padding: 2vh 0px 0px 0px;
`;

const StyledLink = styled.a`
  cursor: pointer;
  padding: 0 10px;
  margin: 0 10px;
  transition: all;
  color: #ffffff;
  opacity: 0.7;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const Logo = styled.a`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0 10px;
  margin: 0 10px;
`;

const GLContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

const ItemWrapper = styled.span`
  display: inline-flex;
`;

type Props = {
  title: string
}

const NavbarItem = ({ value, href, target }) => (
  <StyledLink href={href} target={target}>
    <Text>{value}</Text>
  </StyledLink>
);

const Header = ({ title }: Props) => (
  <Container>
    <Navbar>
      <Logo>
        <Text>NICK ITALIANO</Text>
      </Logo>
      <ItemWrapper>
        <NavbarItem value='Github' href={'https://github.com/nitaliano'} target="_blank" />
        <NavbarItem value='Contact' href="mailto:ni6@njit.edu" />
      </ItemWrapper>
    </Navbar>
    <Hero title={title} />
    
    <GLContainer>
      <ParticleScene />
    </GLContainer>
  </Container>
)

export default Header;
