import * as React from 'react';
import styled from 'styled-components';

import Text from './Text';

type TitleProps = {
  children: React.ReactNode
}

const Container = styled.div`

`;

const Subtitle: React.FC<Props> = ({ children }) => (
  <Container>
    <Text>{children}</Text>
  </Container>
);

export default Subtitle;

