import * as React from 'react';
import styled from 'styled-components';

import Title from './Title';
import Subtitle from './Subtitle';

type Props = {
  title: string
  subtitle: string
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  margin-top: -2vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Hero = ({ title, subtitle }: Props) => (
    <Container>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
    </Container>
);

export default Hero;
